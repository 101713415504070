@import './variables.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  min-height: 100%;
  position: relative;
  color: $color-secondary;
  font-family: $body-font;
}

body {
  background: $color-background;
}

#root {
  height: 100dvh;
  display: grid;
  grid-template-columns: auto auto;
}

.no-match {
  flex: 1;
}

.no-match * {
  text-align: center;
}

.no-match h1 {
  font-size: 240px;
  margin: 0;
}

@media (max-width: 1400px) {
  #root {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
  }

  .scrollbar-jitter-fix {
    margin-left: 0px;
  }

  footer p {
    margin: auto;
  }
}

@media (max-width: 414px) {
  #root {
    max-width: 100vw;
    overflow-x: hidden;
  }

  .no-match {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 55vh;
  }

  .no-match h1 {
    font-size: 120px;
    margin-top: auto;
  }

  .no-match p {
    margin-bottom: auto;
  }
}
