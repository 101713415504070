@import '../../../variables.scss';

footer {
  display: flex;
  min-height: $footer-height;

  p {
    margin-top: 10px;
  }
}
