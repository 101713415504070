@import '../../../variables.scss';

body:has(#piece-display),
.piece-master-view {
  overflow: hidden;
}

#piece-display {
  display: flex;
  margin: auto;
  align-self: center;

  > div {
    margin: auto;
    align-self: center;
  }

  img {
    max-height: 100vh;
    max-width: 100vw;
  }
}

.portrait-image {
  height: 100%;
  width: 50%;
  flex: 1.5 1;
}

.landscape-display {
  flex-direction: column;
}

.piece-container {
  margin: auto;
}

.modal {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 999;

  .piece-container {
    position: relative;
    z-index: 1;
    height: 100vh;
    display: flex;
  }

  #piece-image img {
    align-self: center;
  }

  .modal-background {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    background: rgba($color: #000000, $alpha: 0.75);
    width: 100vw;
    height: 100vh;
  }
}

@media (max-width: 500px) {
  .modal {
    .piece-container {
      padding-bottom: 100px;
    }
  }
}

@media (max-width: 1400px) {
  .modal {
    height: 100%;
    overflow-x: hidden;

    .piece-container {
      width: auto;
      overflow-x: hidden;
    }
  }

  #piece-display {
    height: auto;
  }

  #piece-image {
    padding: 5px;
    align-items: flex-start;
  }

  .modal #piece-image {
    flex: 0;
  }
}
