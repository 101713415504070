@import '../../../variables.scss';

header {
  margin-left: auto;

  h1 {
    margin: 0;
    margin-bottom: 5px;
    margin-top: 10px;
    font-family: $title-font;
    font-size: 28px;
    text-align: center;
    color: $color-font-color;
  }
}

.header-inner-container {
  position: sticky;
  top: 75px;
}

#site-logo-wrapper {
  display: flex;
  justify-content: center;

  #site-logo {
    align-self: center;
    height: 100%;
    max-width: 184px;
    flex-basis: 150px;
  }
}

.sidebar-link {
  display: flex;
  align-items: center;
  color: $color-font-color;
  font-size: 21pt;
  font-weight: 100;
  text-decoration: none;
  margin-bottom: 5px;
}

.sidebar-link:hover,
.sidebar-link:active {
  color: $color-accent;
}

nav {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-top: 30px;

  .nav-svg {
    height: 32px;
    width: auto;
    margin-right: 10px;
  }
}

#root:has(#portfolio-display) nav > .sidebar-link:first-of-type,
nav .active {
  color: $color-accent;
}

.shop-links {
  margin-top: 48px;
}

.nav-display-button {
  display: none;
}

#social-network-links {
  margin-top: 48px;
  margin-left: -5px;

  svg {
    width: 32px;
    height: auto;
    margin: 0 5px;
  }
}

.social-network-link {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.social-network-link,
.social-network-link:visited {
  color: $color-font-color;
  text-decoration: none;
}

.social-network-link:hover,
.social-network-link:active {
  color: $color-accent;
}

// SVG Path Colors
// NATURAL STATE
.social-network-link path {
  // fill: $color-background-shade;
}

// ACTIVE STATE
.social-network-link:hover path,
.social-network-link:active path {
  fill: $color-accent;
}

.social-network-link:hover .cara-icon circle {
  fill: $color-accent;
}

.social-network-link:hover .cara-icon path {
  fill: $color-background;
}
