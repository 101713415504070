@import '../../../variables.scss';

#portfolio-display {
  display: grid;
  position: relative;
  text-align: center;
  justify-self: flex-start;
  margin-left: 42px;
  width: $content-width;
  margin-bottom: 60px;
}

.portfolio-pieces {
  display: grid;
  grid-template-columns: auto auto;
  overflow-x: hidden;

  a {
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.span-two-columns {
  grid-column: 1 / 3;
}

.piece-wrapper {
  position: relative;
  justify-self: center;
}

.portfolio-piece {
  position: relative;
  display: flex;
  align-items: baseline;

  .center-align {
    margin: auto;
  }

  .left-align {
    margin-left: 0px;
    margin-right: auto;
  }

  .right-align {
    margin-right: 0px;
    margin-left: auto;
  }
}

.piece-title {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  background: rgba($color: #000000, $alpha: 0.5);
  color: white;

  font-size: 1.5em;
  font-weight: bold;

  pointer-events: none;
}

.piece-wrapper:hover .piece-title {
  animation: fadeIn 0.4s forwards 1 normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  #portfolio-display {
    margin: 0;
    width: auto;
  }

  #portfolio-display {
    max-height: calc(100dvh - 60px);
  }
}

@media (max-width: 500px) {
  #portfolio-display {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .two-column .piece-wrapper {
    width: auto;
  }

  .one-column,
  .two-column {
    width: 95%;
  }

  .portfolio-piece {
    margin: auto;

    .left-align {
      margin: auto;
    }

    .right-align {
      margin: auto;
    }
  }

  .piece-title {
    display: none;
  }
}
