@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&Roboto&display=swap');

$color-accent: #d20660;
$color-secondary: #424242;
$color-background: #f3f3f3;
$color-tab-font: #fff;
$color-background-shade: #424242;
$color-word-bubble: #fff;
$color-font-color: #424242;

$color-button-pressed: rgb(225, 1, 60);

$content-width: 1100px;

$accent-bar-height: 10px;
$footer-height: 50px;
$piece-display-nav-height: 80px;
$piece-display-height: calc(
  100vh - #{$footer-height} - #{$accent-bar-height} - #{$piece-display-nav-height}
);

$title-font: 'Archivo Black', sans-serif;
$body-font: 'Roboto', sans-serif;
