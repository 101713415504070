@import '../../../../variables.scss';

.portfolio-tab-region {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: $color-background;
  padding-top: 30px;
  padding-bottom: 10px;
  margin: 0px;
}

.portfolio-tab-container {
  width: min-content;
  overflow: scroll;
  width: 100%;
  scroll-behavior: smooth;
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.disable-scrollbars {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#portfolio-tabs {
  display: flex;
  position: relative;
  width: min-content;
  margin: auto;

  a {
    text-decoration: none;
  }
}

.tab {
  background: $color-background-shade;
  color: $color-tab-font;
  padding: 10px 16px;
  border-radius: 40px;
  margin: 0px 8px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.75rem;
  user-select: none;
  white-space: nowrap;
}

.tab:hover,
.tab:active,
#portfolio-tabs .active {
  color: #ffffff;
  background: $color-accent;
}

@media (max-width: 1400px) {
  .portfolio-tab-region {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
