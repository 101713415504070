@media (max-width: 1400px) {
  .about-me-wrapper {
    margin-top: 0px;
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 100px;

    > * {
      grid-column: span 2 !important;
    }
  }

  #about-me {
    margin: auto;
    width: 90%;
  }

  .profile-picture-container img {
    float: none;
  }

  .profile-picture-container img,
  .call-to-action img {
    max-width: 100%;
  }
}

@media (max-width: 414px) {
  .processes {
    grid-template-columns: none;

    .process img {
      display: none;
    }
  }

  .call-to-action,
  .call-to-action img {
    width: 95%;
    margin: 0px auto;
  }

  .attributes {
    font-size: 16px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 100px;
  }
}
