@import '../../../variables.scss';

.about-me-wrapper {
  max-width: $content-width;
  margin-left: 42px;
  margin-top: 60px;
  margin-bottom: 50px;

  h1 {
    text-align: center;
    font-size: 18px;
    color: $color-accent;
  }

  p {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-indent: 1em;
    text-align: justify;
  }

  strong {
    color: $color-accent;
  }
}

.profile-picture-container {
  text-align: center;

  img {
    float: left;
    margin-right: 20px;
    max-height: 75vh;
    max-width: 95vw;
  }
}

#about-me {
  font-size: 12pt;
  flex-flow: column;
  flex: 1.05;
  margin: 20px;
  margin-top: 0px;

  li {
    position: relative;
    list-style-position: inside;

    &::marker {
      margin: 0;
    }
  }

  h1 {
    text-align: left;
  }
}

.processes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: 2em;

  .process {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: calc(#{$content-width} / 3 - 10%);
    }
  }
}

.call-to-action {
  margin-top: 50px;

  .call-to-action-text {
    margin-bottom: -30px;
  }

  .call-to-action-bubble {
    background-color: $color-word-bubble;
    border-radius: 40px;
    padding: 30px;

    p {
      color: black;
    }

    a,
    a:visited {
      color: $color-accent;
      text-decoration: none;
      font-weight: bold;
    }

    a:hover,
    a:active {
      text-decoration: underline;
    }
  }

  .message_trail {
    fill: $color-word-bubble;
    height: 60px;
    width: auto;
    margin-left: 40%;
  }

  img {
    width: $content-width;
  }
}

.attributes {
  font-size: 8pt;
  p {
    text-indent: 0px;
    text-align: left;
  }

  a {
    color: $color-accent;
    text-decoration: none;
  }
}
