.nav-button-container {
  display: grid;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 30px;
  font-size: 8pt;
  pointer-events: all;
  cursor: pointer;
}

.button-left {
  background: rgb(255, 242, 216);
  background: linear-gradient(
    90deg,
    rgba(255, 242, 216, 1) 30%,
    rgba(255, 242, 216, 0) 100%
  );
}

.button-right {
  justify-self: right;
  background: rgb(255, 242, 216);
  background: linear-gradient(
    90deg,
    rgba(255, 242, 216, 0) 0%,
    rgba(255, 242, 216, 1) 70%
  );
}

.hide {
  display: none;
}
