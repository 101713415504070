@import '../../../variables.scss';

@media (max-width: 1400px) {
  $logo-size: 40px;

  header {
    position: fixed;
    bottom: 0px;
    z-index: 100;
    width: 100%;
    margin: 0;
  }

  #site-logo-wrapper {
    margin-left: 10px;

    #site-logo {
      height: $logo-size;
      width: $logo-size;
    }
  }

  .header-top {
    display: flex;
    align-items: center;
    background: $color-secondary;
    height: 60px;

    // Hides the menu animation from popping in on iOS
    &::after {
      content: '';
      position: absolute;
      top: 60px;
      background-color: $color-secondary;
      height: 200px;
      width: 100%;
    }

    h1 {
      color: $color-background;
      margin-right: auto;
      margin-left: 10px;
      margin-top: 6px;
    }

    .nav-display-button {
      display: block;
      background: transparent;
      border: none;
      padding: 15px;
      margin-right: 5px;
      color: $color-accent;
    }
  }

  .header-bottom {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 250px;
    height: 500px;
    z-index: -1;
    background-color: $color-secondary;
    border-radius: 20px 20px 0 0;
    display: grid;
    justify-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    a {
      color: $color-background;
    }

    .active {
      color: $color-accent;
    }

    nav {
      margin: 0;
      margin-top: 30px;
    }

    nav,
    #social-network-links {
      width: fit-content;
    }

    .shop-links {
      margin-top: 10px;
    }

    // NATURAL STATE
    .social-network-link,
    .social-network-link:visited {
      color: $color-background;
      text-decoration: none;
    }

    .social-network-link path,
    .cara-icon circle {
      fill: $color-background;
    }

    // Cara Icon
    .cara-icon path,
    .cara-icon:visited path {
      fill: $color-secondary;
    }

    .cara-icon:hover circle,
    .cara-icon:active circle {
      fill: $color-accent;
    }

    .cara-icon:hover path,
    .cara-icon:active path {
      fill: $color-secondary;
    }
  }

  .open_nav .header-bottom {
    animation: moveup 0.5s ease 0s 1 normal forwards;
  }

  .open_nav .grayness {
    content: '';
    opacity: 0.5;
    background-color: black;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -3;
    top: 0px;
  }

  body:has(.open_nav),
  .grayness {
    overflow: hidden;
  }
}

@keyframes moveup {
  0% {
    top: 0px;
  }
  100% {
    top: -420px;
  }
}
